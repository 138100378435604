import React from 'react'
import { Header } from '../components/header/Header'
import Contact from '../pages/contact/Contact'
import { Outlet, useLocation } from 'react-router-dom'

export const MainLayouts = () => {

    const location = useLocation();
    return (
        <>
            <Header />
            <Outlet />
            {location.pathname !== '/thank-you' && <Contact />}
        </>
    )
}
