import Imagen1 from '../imagenes/betania-plazaII.jpg'
import betaniaPlaza1 from '../imagenes/BETANIA-PLAZA-II-FINAL.OBRA.1.jpg'
import betaniaPlaza2 from '../imagenes/BETANIA-PLAZA-II-FINAL.OBRA.2.jpg'
import betaniaPlaza3 from '../imagenes/BETANIA-PLAZA-II-FINAL.OBRA.3.jpg'
import betaniaPlaza4 from '../imagenes/BETANIA-PLAZA-II-FINAL.OBRA.4.jpg'
import betaniaPlaza5 from '../imagenes/BETANIA-PLAZA-II-FINAL.OBRA.5.jpg'
import betaniaPlaza1Proceso from '../imagenes/BETANIA-PLAZA-II-PROCESO.OBRA.1.jpg'
import betaniaPlaza2Proceso from '../imagenes/BETANIA-PLAZA-II-PROCESO.OBRA.2.jpg'
import betaniaPlaza3Proceso from '../imagenes/BETANIA-PLAZA-II-PROCESO.OBRA.3.jpg'
import betaniaPlaza4Proceso from '../imagenes/BETANIA-PLAZA-II-PROCESO.OBRA.4.jpg'
import betaniaPlaza5Proceso from '../imagenes/BETANIA-PLAZA-II-PROCESO.OBRA.5.jpg'
import casaAgPortada from '../imagenes/Portada-home-1.jpg'
import casaAgFinalizada1 from '../imagenes/Cocina-AG-FINAL.OBRA.1.jpg'
import casaAgFinalizada2 from '../imagenes/Cocina-AG-FINAL.OBRA.2.jpg'
import casaAgFinalizada3 from '../imagenes/Cocina-AG-FINAL.OBRA.3.jpg'
import casaAgFinalizada4 from '../imagenes/Cocina-AG-FINAL.OBRA.4.jpg'
import casaAgFinalizada5 from '../imagenes/Cocina-AG-FINAL.OBRA.5.jpg'
import casaAgFinalizada6 from '../imagenes/Cocina-AG-FINAL.OBRA.6.jpg'
import casaAgFinalizada7 from '../imagenes/Cocina-AG-FINAL.OBRA.7.jpg'
import casaAgProceso1 from '../imagenes/Cocina-AG-PROCESO.OBRA.1.jpg'
import casaAgProceso2 from '../imagenes/Cocina-AG-PROCESO.OBRA.2.jpg'
import casaAgProceso3 from '../imagenes/Cocina-AG-PROCESO.OBRA.3.jpg'
import betaniaPlazaIFinal1 from '../imagenes/BETANIA-PLAZA-I-FINAL.OBRA.1.jpg'
import betaniaPlazaIFinal2 from '../imagenes/BETANIA-PLAZA-I-FINAL.OBRA.2.jpg'
import betaniaPlazaIFinal3 from '../imagenes/BETANIA-PLAZA-I-FINAL.OBRA.3.jpg'
import betaniaPlazaIFinal4 from '../imagenes/BETANIA-PLAZA-I-FINAL.OBRA.4.jpg'
import betaniaPlazaIFinal5 from '../imagenes/BETANIA-PLAZA-I-FINAL.OBRA.5.jpg'
import betaniaPlazaIProceso1 from '../imagenes/BETANIA-PLAZA-I-PROCESO.OBRA.1.jpg'
import betaniaPlazaIProceso2 from '../imagenes/BETANIA-PLAZA-I-PROCESO.OBRA.2.jpg'
import betaniaPlazaIProceso3 from '../imagenes/BETANIA-PLAZA-I-PROCESO.OBRA.3.jpg'
import betaniaPlazaIProceso4 from '../imagenes/BETANIA-PLAZA-I-PROCESO.OBRA.4.jpg'
import betaniaPlazaIProceso5 from '../imagenes/BETANIA-PLAZA-I-PROCESO.OBRA.5.jpg'
import ituzaingoFinal1 from '../imagenes/ITUZAINGO-FINAL.OBRA.1.jpg'
import ituzaingoFinal2 from '../imagenes/ITUZAINGO-FINAL.OBRA.2.jpg'
import ituzaingoFinal3 from '../imagenes/ITUZAINGO-FINAL.OBRA.3.jpg'
import ituzaingoFinal4 from '../imagenes/ITUZAINGO-FINAL.OBRA.4.jpg'
import ituzaingoFinal5 from '../imagenes/ITUZAINGO-FINAL.OBRA.5.jpg'
import ituzaingoFinal6 from '../imagenes/ITUZAINGO-FINAL.OBRA.6.jpg'
import ituzaingoFinal7 from '../imagenes/ITUZAINGO-FINAL.OBRA.7.jpg'
import ituzaingoFinal8 from '../imagenes/ITUZAINGO-FINAL.OBRA.8.jpg'
import ituzaingoProceso1 from '../imagenes/ITUZAINGO-PROCESO.OBRA.1.jpg'
import ituzaingoProceso2 from '../imagenes/ITUZAINGO-PROCESO.OBRA.2.jpg'
import ituzaingoProceso3 from '../imagenes/ITUZAINGO-PROCESO.OBRA.3.jpg'
import ituzaingoProceso4 from '../imagenes/ITUZAINGO-PROCESO.OBRA.4.jpg'
import ituzaingoProceso5 from '../imagenes/ITUZAINGO-PROCESO.OBRA.5.jpg'
import ituzaingoProceso6 from '../imagenes/ITUZAINGO-PROCESO.OBRA.6.jpg'
import ituzaingoProceso7 from '../imagenes/ITUZAINGO-PROCESO.OBRA.7.jpg'
import ituzaingoProceso8 from '../imagenes/ITUZAINGO-PROCESO.OBRA.8.jpg'
import ituzaingoProceso9 from '../imagenes/ITUZAINGO-PROCESO.OBRA.9.jpg'
import ituzaingoProceso10 from '../imagenes/ITUZAINGO-PROCESO.OBRA.10.jpg'
import ituzaingoProceso11 from '../imagenes/ITUZAINGO-PROCESO.OBRA.11.jpg'
import ituzaingoProceso12 from '../imagenes/ITUZAINGO-PROCESO.OBRA.12.jpg'
import CasaNgPortada from '../imagenes/CASA.NG-PORTADA.jpg'
import CasaNgFina1 from '../imagenes/CASA.NG-FINAL.OBRA.1.jpg'
import CasaNgFina2 from '../imagenes/CASA.NG-FINAL.OBRA.2.jpg'
import CasaNgFina3 from '../imagenes/CASA.NG-FINAL.OBRA.3.jpg'
import CasaNgFina4 from '../imagenes/CASA.NG-FINAL.OBRA.4.jpg'
import CasaNgFina5 from '../imagenes/CASA.NG-FINAL.OBRA.5.jpg'
import CasaNgFina6 from '../imagenes/CASA.NG-FINAL.OBRA.6.jpg'
import CasaNgProceso1 from '../imagenes/CASA.NG-PROCESO.OBRA.1.jpg'
import CasaNgProceso2 from '../imagenes/CASA.NG-PROCESO.OBRA.2.jpg'
import CasaNgProceso3 from '../imagenes/CASA.NG-PROCESO.OBRA.3.jpg'
import CasaNgProceso4 from '../imagenes/CASA.NG-PROCESO.OBRA.4.jpg'
import CasaNgProceso5 from '../imagenes/CASA.NG-PROCESO.OBRA.5.jpg'
import CasaNgProceso6 from '../imagenes/CASA.NG-PROCESO.OBRA.6.jpg'
import CasaNgProceso7 from '../imagenes/CASA.NG-PROCESO.OBRA.7.jpg'
import CasaNgProceso8 from '../imagenes/CASA.NG-PROCESO.OBRA.8.jpg'
import CasaNgProceso9 from '../imagenes/CASA.NG-PROCESO.OBRA.9.jpg'
import CasaNgProceso10 from '../imagenes/CASA.NG-PROCESO.OBRA.10.jpg'
import RondeauPortada from '../imagenes/BAÑO-RONDEAU-PORTADA.jpg'
import RondeauFinal1 from '../imagenes/BAÑO-RONDEAU-FINAL.OBRA.1.jpg'
import RondeauFinal2 from '../imagenes/BAÑO-RONDEAU-FINAL.OBRA.2.jpg'
import RondeauFinal3 from '../imagenes/BAÑO-RONDEAU-FINAL.OBRA.3.jpg'
import RondeauFinal4 from '../imagenes/BAÑO-RONDEAU-FINAL.OBRA.4.jpg'
import RondeauFinal5 from '../imagenes/BAÑO-RONDEAU-FINAL.OBRA.5.jpg'
import RondeauProceso1 from '../imagenes/BAÑO-RONDEAU-PROCESO.OBRA.1.jpg'
import RondeauProceso2 from '../imagenes/BAÑO-RONDEAU-PROCESO.OBRA.2.jpg'
import RondeauProceso3 from '../imagenes/BAÑO-RONDEAU-PROCESO.OBRA.3.jpg'
import RondeauProceso4 from '../imagenes/BAÑO-RONDEAU-PROCESO.OBRA.4.jpg'
import RondeauProceso5 from '../imagenes/BAÑO-RONDEAU-PROCESO.OBRA.5.jpg'
import RondeauProceso6 from '../imagenes/BAÑO-RONDEAU-PROCESO.OBRA.6.jpg'
import RondeauProceso7 from '../imagenes/BAÑO-RONDEAU-PROCESO.OBRA.7.jpg'
import RondeauProceso8 from '../imagenes/BAÑO-RONDEAU-PROCESO.OBRA.8.jpg'
import RondeauProceso9 from '../imagenes/BAÑO-RONDEAU-PROCESO.OBRA.9.jpg'
import RondeauProceso10 from '../imagenes/BAÑO-RONDEAU-PROCESO.OBRA.10.jpg'
import RondeauProceso11 from '../imagenes/BAÑO-RONDEAU-PROCESO.OBRA.11.jpg'
import OrizaPortada from '../imagenes/ORIZA-PORTADA.jpg'
import OrizaFinal1 from '../imagenes/ORIZA-FINAL.OBRA.1.jpg'
import OrizaFinal2 from '../imagenes/ORIZA-FINAL.OBRA.2.jpg'
import OrizaFinal3 from '../imagenes/ORIZA-FINAL.OBRA.3.jpg'
import OrizaFinal4 from '../imagenes/ORIZA-FINAL.OBRA.4.jpg'
import OrizaFinal5 from '../imagenes/ORIZA-FINAL.OBRA.5.jpg'
import OrizaFinal6 from '../imagenes/ORIZA-FINAL.OBRA.6.jpg'
import OrizaFinal7 from '../imagenes/ORIZA-FINAL.OBRA.7.jpg'
import OrizaFinal8 from '../imagenes/ORIZA-FINAL.OBRA.8.jpg'
import OrizaFinal9 from '../imagenes/ORIZA-FINAL.OBRA.9.jpg'
import OrizaFinal10 from '../imagenes/ORIZA-FINAL.OBRA.10.jpg'
import OrizaFinal11 from '../imagenes/ORIZA-FINAL.OBRA.11.jpg'
import OrizaFinal12 from '../imagenes/ORIZA-FINAL.OBRA.12.jpg'
import OrizaFinal13 from '../imagenes/ORIZA-FINAL.OBRA.13.jpg'
import OrizaFinal14 from '../imagenes/ORIZA-FINAL.OBRA.14.jpg'
import OrizaFinal15 from '../imagenes/ORIZA-FINAL.OBRA.15.jpg'
import OrizaProceso1 from '../imagenes/ORIZA-PROCESO.OBRA.1.jpg'
import OrizaProceso2 from '../imagenes/ORIZA-PROCESO.OBRA.2.jpg'
import OrizaProceso3 from '../imagenes/ORIZA-PROCESO.OBRA.3.jpg'
import OrizaProceso4 from '../imagenes/ORIZA-PROCESO.OBRA.4.jpg'
import OrizaProceso5 from '../imagenes/ORIZA-PROCESO.OBRA.5.jpg'
import SorellePortada from '../imagenes/SORELLE-PORTADA.jpg'
import SorelleFinal1 from '../imagenes/SORELLE-FINAL.OBRA.1.jpg'
import SorelleFinal3 from '../imagenes/SORELLE-FINAL.OBRA.3.jpg'
import SorelleFinal4 from '../imagenes/SORELLE-FINAL.OBRA.4.jpg'
import SorelleFinal5 from '../imagenes/SORELLE-FINAL.OBRA.5.jpg'
import SorelleFinal6 from '../imagenes/SORELLE-FINAL.OBRA.6.jpg'
import SorelleFinal7 from '../imagenes/SORELLE-FINAL.OBRA.7.jpg'
import SorelleFinal8 from '../imagenes/SORELLE-FINAL.OBRA.8.jpg'
import SorelleFinal9 from '../imagenes/SORELLE-FINAL.OBRA.9.jpg'
import SorelleFinal10 from '../imagenes/SORELLE-FINAL.OBRA.10.jpg'
import SorelleFinal11 from '../imagenes/SORELLE-FINAL.OBRA.11.jpg'
import SorelleFinal12 from '../imagenes/SORELLE-FINAL.OBRA.12.jpg'
import SorelleFinal13 from '../imagenes/SORELLE-FINAL.OBRA.13.jpg'
import SorelleProceso1 from '../imagenes/SORELLE-PROCESO.OBRA.1.jpg'
import SorelleProceso2 from '../imagenes/SORELLE-PROCESO.OBRA.2.jpg'
import SorelleProceso3 from '../imagenes/SORELLE-PROCESO.OBRA.3.jpg'
import SorelleProceso4 from '../imagenes/SORELLE-PROCESO.OBRA.4.jpg'
import SorelleProceso5 from '../imagenes/SORELLE-PROCESO.OBRA.5.jpg'
import DuomoPortada from '../imagenes/DOUMO-PORTADA.jpg'
import DuomoFinal1 from '../imagenes/DUOMO-FINAL.OBRA.1.jpg'
import DuomoFinal2 from '../imagenes/DUOMO-FINAL.OBRA.2.jpg'
import DuomoFinal3 from '../imagenes/DUOMO-FINAL.OBRA.3.jpg'
import DuomoFinal4 from '../imagenes/DUOMO-FINAL.OBRA.4.jpg'
import DuomoFinal5 from '../imagenes/DUOMO-FINAL.OBRA.5.jpg'
import DuomoProceso1 from '../imagenes/DUOMO-PROCESO.OBRA.1.jpg'
import DuomoProceso2 from '../imagenes/DUOMO-PROCESO.OBRA.2.jpg'
import DuomoProceso3 from '../imagenes/DUOMO-PROCESO.OBRA.3.jpg'
import DuomoProceso4 from '../imagenes/DUOMO-PROCESO.OBRA.4.jpg'
import DuomoProceso5 from '../imagenes/DUOMO-PROCESO.OBRA.5.jpg'
import DuomoProceso6 from '../imagenes/DUOMO-PROCESO.OBRA.6.jpg'
import DuomoProceso7 from '../imagenes/DUOMO-PROCESO.OBRA.7.jpg'
import DuomoProceso8 from '../imagenes/DUOMO-PROCESO.OBRA.8.jpg'
import CañuelaPortada from '../imagenes/CAÑUELAS-PORTADA.jpg'
import CañuelasFinal1 from '../imagenes/CAÑUELAS-FINAL.OBRA.1.jpg'
import CañuelasFinal2 from '../imagenes/CAÑUELAS-FINAL.OBRA.2.jpg'
import CañuelasFinal3 from '../imagenes/CAÑUELAS-FINAL.OBRA.3.jpg'
import CañuelasFinal4 from '../imagenes/CAÑUELAS-FINAL.OBRA.4.jpg'
import CañuelasFinal5 from '../imagenes/CAÑUELAS-FINAL.OBRA.5.jpg'
import CañuelasFinal6 from '../imagenes/CAÑUELAS-FINAL.OBRA.6.jpg'
import CañuelasFinal7 from '../imagenes/CAÑUELAS-FINAL.OBRA.7.jpg'
import CañuelasFinal8 from '../imagenes/CAÑUELAS-FINAL.OBRA.8.jpg'
import CañuelasFinal9 from '../imagenes/CAÑUELAS-FINAL.OBRA.9.jpg'
import CañuelasFinal10 from '../imagenes/CAÑUELAS-FINAL.OBRA.10.jpg'
import CañuelasFinal11 from '../imagenes/CAÑUELAS-FINAL.OBRA.11.jpg'
import CañuelasFinal12 from '../imagenes/CAÑUELAS-FINAL.OBRA.12.jpg'
import CañuelasFinal13 from '../imagenes/CAÑUELAS-FINAL.OBRA.13.jpg'
import CañuelasFinal14 from '../imagenes/CAÑUELAS-FINAL.OBRA.14.jpg'
import CañuelasFinal15 from '../imagenes/CAÑUELAS-FINAL.OBRA.15.jpg'
import CañuelasFinal16 from '../imagenes/CAÑUELAS-FINAL.OBRA.16.jpg'
import CañuelasFinal17 from '../imagenes/CAÑUELAS-FINAL.OBRA.17.jpg'
import CañuelasFinal18 from '../imagenes/CAÑUELAS-FINAL.OBRA.18.jpg'
import CañuelasFinal19 from '../imagenes/CAÑUELAS-FINAL.OBRA.19.jpg'
import CañuelasFinal20 from '../imagenes/CAÑUELAS-FINAL.OBRA.20.jpg'
import CañuelasFinal21 from '../imagenes/CAÑUELAS-FINAL.OBRA.21.jpg'
import CañuelasFinal22 from '../imagenes/CAÑUELAS-FINAL.OBRA.22.jpg'
import CañuelasProceso1 from '../videos/cañuelas-proceso-obra1.mp4'
import CañuelasProceso2 from '../videos/cañuelas-proceso-obra2.mp4'
import GradPortada from '../imagenes/GRAD.PORTADA.jpg'
import GradFinal1 from '../imagenes/GRAD-FINAL.OBRA.1.jpg'
import GradFinal2 from '../imagenes/GRAD-FINAL.OBRA.2.jpg'
import GradFinal3 from '../imagenes/GRAD-FINAL.OBRA.3.jpg'
import GradFinal4 from '../imagenes/GRAD-FINAL.OBRA.4.jpg'
import GradFinal5 from '../imagenes/GRAD-FINAL.OBRA.5.jpg'
import GradFinal6 from '../imagenes/GRAD-FINAL.OBRA.6.jpg'
import GradFinal7 from '../imagenes/GRAD-FINAL.OBRA.7.jpg'
import GradFinal8 from '../imagenes/GRAD-FINAL.OBRA.8.jpg'
import GradProceso1 from '../imagenes/GRAD-PROCESO.OBRA.1.jpg'
import GradProceso2 from '../imagenes/GRAD-PROCESO.OBRA.2.jpg'
import GradProceso3 from '../imagenes/GRAD-PROCESO.OBRA.3.jpg'
import GradProceso4 from '../imagenes/GRAD-PROCESO.OBRA.4.jpg'
import GradProceso5 from '../imagenes/GRAD-PROCESO.OBRA.5.jpg'
import GradProceso6 from '../imagenes/GRAD-PROCESO.OBRA.6.jpg'




const projectData = {
    1: {
        nombre: 'BETANIA PLAZA I',
        descripcion: ['Les presentamos Betania Plaza I, un trabajo integral de Proyecto y Ejecución de Obra, con la condición del comitente de ser un departamento destinado únicamente para alquiler temporario ubicado en el Centro de Ciudad de Córdoba.', 'De la premisa de su funcionalidad nace la idea de trabajar con materiales simples, austeros y de bajo mantenimiento como la madera aplicando una identidad al espacio y creando una calidez que se acompaña con sus tonalidades neutras en los muros.', 'La integración de espejos orgánicos y el estratégico juego de la iluminación amplía notablemente la sensación del espacio logrando una transformación completa del departamento y generando el confort de sus habitantes temporarios.'],
        portada: betaniaPlazaIFinal4,
        imgFinalizado: [

            { src: betaniaPlazaIFinal1 },
            { src: betaniaPlazaIFinal2 },
            { src: betaniaPlazaIFinal3 },
            { src: betaniaPlazaIFinal4 },
            { src: betaniaPlazaIFinal5 },
        ],
        imgProceso: [
            { src: betaniaPlazaIProceso1 },
            { src: betaniaPlazaIProceso2 },
            { src: betaniaPlazaIProceso3 },
            { src: betaniaPlazaIProceso4 },
            { src: betaniaPlazaIProceso5 },
        ],
        location: 'Nueva Córdoba, Córdoba',
        año: '2023',
        typeService: 'Interiorismo',
        category: 'Alquiler Temporario',
    },
    2: {
        nombre: 'BETANIA PLAZA II',
        descripcion: ['Les presentamos Betania Plaza II, un trabajo integral de Proyecto y Ejecución de obra que se realizó paralelamente a Betania Plaza I', 'De la condición de sus comitentes, departamento destinado a ser utilizado como alquiler temporario mediante la plataforma Airbnb, nace la idea de trabajar con materiales simples, austeros y de bajo mantenimiento como la madera, aplicando una identidad al espacio y al mismo tiempo crear un contraste con las tonalidades frías elegidas para las paredes.', 'La intervención de un estratégico juego de la iluminación es la fórmula que nos lleva a lograr la sensación de amplitud de los ambientes que tanto se buscaba. De esta forma, se genera una transformación completa del departamento y el confort de sus habitantes temporarios.'],
        portada: Imagen1,
        imgFinalizado: [

            { src: betaniaPlaza1 },
            { src: betaniaPlaza2 },
            { src: betaniaPlaza3 },
            { src: betaniaPlaza4 },
            { src: betaniaPlaza5 },
        ],
        imgProceso: [
            { src: betaniaPlaza1Proceso },
            { src: betaniaPlaza2Proceso },
            { src: betaniaPlaza3Proceso },
            { src: betaniaPlaza4Proceso },
            { src: betaniaPlaza5Proceso },
        ],

        location: 'Nueva Córdoba, Córdoba',
        año: '2023',
        typeService: 'Interiorismo',
        category: 'Alquiler Temporario',

    },
    3: {
        nombre: 'COCINA AG',
        descripcion: ['Les presentamos Cocina AG, un trabajo integral de Proyecto y Ejecución de Obra de una Remodelación que se le realizó a esta cocina de una vivienda ubicada en Villa Belgrano, zona norte de la Ciudad de Córdoba. Del encargo de sus dueños de cambiarle la cara y modernizar este espacio tan importante dentro de la casa y sumarle un valor agregado a la propiedad, nace la idea de trabajar con un diseño atemporal y funcional sobre todas la cosas.', 'La paleta de colores neutros como el gris topo dialogan con las vetas de la Piedra PuraStone quien es la protagonista en este proyecto. Los muebles laqueados blanco acompañan con su brillo y suman al gran valor del ambiente.', 'Como siempre la intervención de la estratégica iluminación en el proyecto termina de cerrar el diseño y le aporta su calidez.La reorganización y estudiada ubicación de los artefactos de cocina junto al diseño de los muebles que amplían el lugar de guardado, le generaron la funcionalidad deseada a los comitentes, logrando una cocina amplia y cómoda para sus habitantes.'],
        portada: casaAgPortada,
        imgFinalizado: [
            { src: casaAgFinalizada1 },
            { src: casaAgFinalizada2 },
            { src: casaAgFinalizada3 },
            { src: casaAgFinalizada4 },
            { src: casaAgFinalizada5 },
            { src: casaAgFinalizada6 },
            { src: casaAgFinalizada7 },
        ],
        imgProceso: [
            { src: casaAgProceso1 },
            { src: casaAgProceso2 },
            { src: casaAgProceso3 },
        ],

        location: 'Villa Belgrano, Cordoba',
        año: '2022',
        typeService: 'Arquitectura - Remodelación',
        category: 'Residencial',
    },
    4: {
        nombre: 'ITUZAINGO',
        descripcion: ['Les presentamos Ituzaingó, un trabajo integral de Proyecto y Ejecución de Obra, la condición del comitente consistia en un departamento que iba a ser destinado para alquiler temporario mediante la plataforma Airbnb, ubicado en Nueva Cordoba, zona céntrica de nuestra Ciudad. De la premisa de su funcionalidad nace la idea de trabajar con materiales simples, austeros y de bajo mantenimiento como la madera utilizando la misma en sus diversas texturas para aplicar una identidad al espacio y crear un diálogo armónico con las tonalidades neutras elegidas para esta tipología.', 'El diseño estratégico de la iluminación junto al juego de espejos amplios pensados en ubicaciones claves, es la fórmula que nos lleva a lograr la sensación de amplitud del espacio que tanto se buscaba. La elección de la correcta Decoración como último detalle termina de generar así una transformación completa del departamento y lograr el confort de sus habitantes temporarios, realzando de esta manera el valor del alquiler de la propiedad.'],
        portada: ituzaingoFinal1,
        imgFinalizado: [
            { src: ituzaingoFinal1 },
            { src: ituzaingoFinal2 },
            { src: ituzaingoFinal3 },
            { src: ituzaingoFinal4 },
            { src: ituzaingoFinal5 },
            { src: ituzaingoFinal6 },
            { src: ituzaingoFinal7 },
            { src: ituzaingoFinal8 },
        ],
        imgProceso: [
            { src: ituzaingoProceso1 },
            { src: ituzaingoProceso2 },
            { src: ituzaingoProceso3 },
            { src: ituzaingoProceso4 },
            { src: ituzaingoProceso5 },
            { src: ituzaingoProceso6 },
            { src: ituzaingoProceso7 },
            { src: ituzaingoProceso8 },
            { src: ituzaingoProceso9 },
            { src: ituzaingoProceso10 },
            { src: ituzaingoProceso11 },
            { src: ituzaingoProceso12 },
        ],

        location: 'Nueva Córdoba, Córdoba',
        año: '2023',
        typeService: 'Interiorismo',
        category: 'Alquiler Temporario',

    },
    5: {
        nombre: 'RONDEAU',
        descripcion: ['Les presentamos otra obra realizada en zona de Nueva Cordoba, zona céntrica de nuestra Ciudad de Cordoba. “Rondeau” fue un trabajo integral de Proyecto y Ejecución de Obra, donde su intervención más imponente, dentro de otras, fue una transformación 100% del Baño. Del punto de partida de su funcionalidad, una unidad destinada al alquiler temporario, nace la idea de trabajar con materiales simples y austeros como revestimientos de Microcemento en muros y Porcelanato en piso, materiales fieles y de bajo mantenimiento, generando una tonalidad armónica y neutra para darle total protagonismo al sector del lavamanos que se resolvió con una pieza entera de Piedra Purastone Blanco Nube.', 'El diseño estratégico de la iluminación junto al diseño moderno del espejo nos permitió dar la sensación de amplitud que estábamos buscando ante un espacio tan pequeño e incómodo. Generando un mayor confort para sus habitantes temporarios y realzando el valor del alquiler de la propiedad.'],
        portada: RondeauPortada,
        imgFinalizado: [
            { src: RondeauFinal1 },
            { src: RondeauFinal2 },
            { src: RondeauFinal3 },
            { src: RondeauFinal4 },
            { src: RondeauFinal5 },
        ],
        imgProceso: [
            { src: RondeauProceso1 },
            { src: RondeauProceso2 },
            { src: RondeauProceso3 },
            { src: RondeauProceso4 },
            { src: RondeauProceso5 },
            { src: RondeauProceso6 },
            { src: RondeauProceso7 },
            { src: RondeauProceso8 },
            { src: RondeauProceso9 },
            { src: RondeauProceso10 },
            { src: RondeauProceso11 },
        ],

        location: 'Nueva Córdoba, Córdoba',
        año: '2023',
        typeService: 'Arquitectura - Remodelación',
        category: 'Residencial'
    },
    6: {
        nombre: 'CASA NG',
        descripcion: ['“CASA NG” fue una Refacción integral de Proyecto y Ejecución de Obra ubicada en barrio Villa Belgrano, zona norte de la Ciudad de Córdoba. De la condición de sus dueños de renovar los espacios y reavivar su imagen exterior nace la idea de trabajar con paletas de colores neutros y atemporales y generar un cambio de estilo con base en la restauración y reciclado.', 'La Cocina como eje de esta transformación se intervino con la restauración de todo su mueble, realizando tareas de pintura y arreglo de herrajes, reutilizando el total de sus componentes, la paleta de colores elegida junto al cambio estratégico del piso realzó la iluminación del espacio logrando la sensación de apertura hacia el patio que se buscaba.', 'La tarea de restauración de muros deteriorados exteriores e interiores y su posterior pintura con la correcta elección de las tonalidades acentuaron la identidad que se esperaba.'],
        portada: CasaNgPortada,
        imgFinalizado: [
            { src: CasaNgFina1 },
            { src: CasaNgFina2 },
            { src: CasaNgFina3 },
            { src: CasaNgFina4 },
            { src: CasaNgFina5 },
            { src: CasaNgFina6 },
        ],
        imgProceso: [
            { src: CasaNgProceso1 },
            { src: CasaNgProceso2 },
            { src: CasaNgProceso3 },
            { src: CasaNgProceso4 },
            { src: CasaNgProceso5 },
            { src: CasaNgProceso6 },
            { src: CasaNgProceso7 },
            { src: CasaNgProceso8 },
            { src: CasaNgProceso9 },
            { src: CasaNgProceso10 },
        ],

        location: 'Villa Belgrano, Córdoba ',
        año: '2023',
        typeService: 'Arquitectura - Remodelación',
        category: 'Residencial'
    },
    7: {
        nombre: 'ORIZA',
        descripcion: ['“ORIZA” fue un trabajo integral de Proyecto y Ejecución de Obra de un Local Gastronómico ubicado en barrio Urca, zona norte de la Ciudad de Córdoba. De la condición de la identidad de marca que ya disponían sus dueños, de crear una tendencia innovadora con sus productos a la venta, nace la idea de pensar un proyecto enfocado en los colores fuertes como punto de partida, trasmitiendo ese “Flow de sabores” al espacio y que el comensal conecte con esa identidad.', 'En cuanto a la materialidad se decidió trabajar con materiales nobles como el ladrillo, hierro y cemento materializando un Estilo Industrial que era el elegido por los dueños como común denominador de todo el proyecto.La intervención de murales genera la sensación de “Flow” que se buscada.El trabajo realizado en la zona de terraza, crear una pérgola que se acompaña con una barra conectada en todo su perímetro, genero un espacio amplio y armónico, junto a su estratégica iluminación, para acomodar más comensales. ', 'Se realizaron tareas de restauración en los núcleos húmedos, cocina y baño, para poner a punto los servicios del Local.“Oriza” fue uno de los primeros trabajos realizados por el estudio, le tenemos un gran afecto y estamos conformes de que se logró transmitir la sensación buscada por la identidad de la marca.'],
        portada: OrizaPortada,
        imgFinalizado: [
            { src: OrizaFinal1 },
            { src: OrizaFinal2 },
            { src: OrizaFinal3 },
            { src: OrizaFinal4 },
            { src: OrizaFinal5 },
            { src: OrizaFinal6 },
            { src: OrizaFinal7 },
            { src: OrizaFinal8 },
            { src: OrizaFinal9 },
            { src: OrizaFinal10 },
            { src: OrizaFinal11 },
            { src: OrizaFinal12 },
            { src: OrizaFinal13 },
            { src: OrizaFinal14 },
            { src: OrizaFinal15 },
        ],
        imgProceso: [
            { src: OrizaProceso1 },
            { src: OrizaProceso2 },
            { src: OrizaProceso3 },
            { src: OrizaProceso4 },
            { src: OrizaProceso5 },
        ],

        location: 'Urca, Córdoba ',
        año: '2019',
        typeService: 'Arquitectura - Interiorismo',
        category: 'Comercial'
    },
    8: {
        nombre: 'SORELLE',
        descripcion: ['“SORELLE” fue un trabajo integral de Proyecto y Ejecución de Obra de un Local de Estética Integral ubicado en barrio Villa Belgrano, zona norte de la Ciudad de Córdoba. De la condición de sus dueñas de crear un ambiente armónico y femenino se decidió trabajar con colores pasteles para mantener un estilo sutil y acogedor', 'Se realizaron varias divisiones en via seca para generar los boxes de los diferentes trabajos que no necesitan que estén conectados visualmente al resto del espacio. Y se incorporaron separaciones en forma de entramado de maderas para generar otros ambientes de conexión visual media para los trabajos menos privados.', 'En cuanto al exterior se restauró la fachada deteriorada y se realizaron tareas de pintura con la paleta de colores que armonice con el interior y ploteos comerciales, también en cuanto a la seguridad se agregaron rejas en vidriera y puerta. '],
        portada: SorellePortada,
        imgFinalizado: [
            { src: SorelleFinal1 },
            { src: SorelleFinal3 },
            { src: SorelleFinal4 },
            { src: SorelleFinal5 },
            { src: SorelleFinal6 },
            { src: SorelleFinal7 },
            { src: SorelleFinal8 },
            { src: SorelleFinal9 },
            { src: SorelleFinal10 },
            { src: SorelleFinal11 },
            { src: SorelleFinal12 },
            { src: SorelleFinal13 },

        ],
        imgProceso: [
            { src: SorelleProceso1 },
            { src: SorelleProceso2 },
            { src: SorelleProceso3 },
            { src: SorelleProceso4 },
            { src: SorelleProceso5 },
        ],

        location: 'Villa Belgrano, Córdoba ',
        año: '2020',
        typeService: 'Arquitectura - Interiorismo',
        category: 'Comercial'
    },
    9: {
        nombre: 'DUOMO',
        descripcion: ['“DUOMO” fue un trabajo integral de Proyecto y Ejecución de Obra de un Departamento ubicado en barrio Nueva Córdoba, zona centro de la Ciudad de Córdoba. De la condición de su dueño de destinar la unidad para Alquiler Temporario, creando un ambiente cómodo y con buena carga estética para poder lucirse a la hora de promocionarlo, se decidió trabajar en un proyecto moderno y atemporal.', 'Se propuso trabajar con materiales de bajo mantenimiento como la madera natural, que a su vez le aporta una calidez especial a los ambientes. De la mano de esta iniciativa, se propusieron colores claros y pasteles a intervenir en las paredes interiores.', 'El juego estratégico de la iluminación, con artefactos minimalistas y luz led que se esconde detrás del mobiliario, provoca la sensación de amplitud del espacio que se buscaba.','El producto final coincidió fielmente al Proyecto presentado, y el encargo del comitente fue logrado con éxito.'],
        portada: DuomoPortada,
        imgFinalizado: [
            { src: DuomoFinal1 },
            { src: DuomoFinal2 },
            { src: DuomoFinal3 },
            { src: DuomoFinal4 },
            { src: DuomoFinal5 },
        ],
        imgProceso: [
            { src: DuomoProceso1 },
            { src: DuomoProceso2 },
            { src: DuomoProceso3 },
            { src: DuomoProceso4 },
            { src: DuomoProceso5 },
            { src: DuomoProceso6 },
            { src: DuomoProceso7 },
            { src: DuomoProceso8 },
        ],

        location: 'Nueva Córdoba, Córdoba ',
        año: '2021',
        typeService: ' Interiorismo',
        category: 'Residencial'
    },
    10: {
        nombre: 'CAÑUELAS',
        descripcion: ['Les presentamos una transformación para alquiler temporario, ubicado en zona Sur de nuestra Ciudad de Córdoba. “Cañuelas” fue un trabajo integral de Proyecto y Ejecución de Obra. Se trabajo con una mezcla de estilos que son tendencia, predominando el Japandi, utilizando la madera natural en su máxima expresión propuestas de diversas maneras para generar muebles a medida del ambiente, que junto a las tonalidades cálidas de los detalles le aportan al espacio la sensación de modernismo y calidez que se buscaba.', 'El diseño estratégico de la iluminación y el análisis de la correcta Decoración como última intervención terminan de generar una transformación completa del Dúplex y lograr la comodidad de sus habitantes temporarios, aportando un valor agregado al alquiler de la propiedad.', 'Para conocer mas sobre el proceso de esta obra los invitamos a recorrer nuestras redes sociales @estudio.jad'],
        portada: CañuelaPortada,
        imgFinalizado: [
            { src: CañuelasFinal1},
            { src: CañuelasFinal2},
            { src: CañuelasFinal3},
            { src: CañuelasFinal4},
            { src: CañuelasFinal5},
            { src: CañuelasFinal6},
            { src: CañuelasFinal7},
            { src: CañuelasFinal8},
            { src: CañuelasFinal9},
            { src: CañuelasFinal10},
            { src: CañuelasFinal11},
            { src: CañuelasFinal12},
            { src: CañuelasFinal13},
            { src: CañuelasFinal14},
            { src: CañuelasFinal15},
            { src: CañuelasFinal16},
            { src: CañuelasFinal17},
            { src: CañuelasFinal18},
            { src: CañuelasFinal19},
            { src: CañuelasFinal20},
            { src: CañuelasFinal21},
            { src: CañuelasFinal22},
           
        ],
        imgProceso: [
            { src: CañuelasProceso1 },
            { src: CañuelasProceso2 },
        ],

        location: 'Zona Sur, Córdoba.',
        año: '2024',
        typeService: 'Arquitectura - Interiorismo.',
        category: 'Alquiler Temporario'
    },
    11: {
        nombre: 'GRAD',
        descripcion: ['“GRAD” fue un trabajo integral de Proyecto y Ejecución de Obra de un Local Comercial ubicado en barrio Bajo Palermo, zona norte de la Ciudad de Córdoba. En base a la función que iba a tener el local, una unidad de negocio destinada a los jóvenes egresados con venta de buzos de fin de cursado, se propuso una mezcla de estilos entre Industrial y Urbano, trabajando con materiales austeros como el caño, la chapa, y el vidrio, aportando una identidad al espacio con graffitis en muros que terminan de marcar el estilo de los jóvenes y le suma dinamismo.', 'Se diseñaron todos los mobiliarios y vidrieras a medida y se aplicaron divisiones en construcción en seco para generar la distribución buscada para oficinas privadas y deposito.'],
        portada: GradPortada,
        imgFinalizado: [
            { src: GradFinal1},
            { src: GradFinal2},
            { src: GradFinal3},
            { src: GradFinal4},
            { src: GradFinal5},
            { src: GradFinal6},
            { src: GradFinal7},
            { src: GradFinal8},      
        ],
        imgProceso: [
            { src: GradProceso1 },
            { src: GradProceso2 },
            { src: GradProceso3 },
            { src: GradProceso4 },
            { src: GradProceso5 },
            { src: GradProceso6 },
         
        ],

        location: 'Bajo Palermo, Córdoba.',
        año: '2018',
        typeService: 'Remodelación - Interiorismo.',
        category: 'Comercial'
    },
};

export default projectData;