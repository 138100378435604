import React from 'react'
import './thankyou.css'

const ThankYou = () => {

    return (
        <div
            className='container-thank-all'
        >
            <div
                className='container-thank-text'
            >
                <h1
                className='title-thank'
                >
                    Gracias por contactarte con ESTUDIO JAD
                </h1>
                <h3 className='subtitle-thank'>
                    Nuestro equipo se pondra en contacto contigo para continuar.
                </h3>
            </div>

        </div>
    )
}

export default ThankYou